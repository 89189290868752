.gallery-section{
      .wp-inner{
            padding-top: 10rem;
            position: relative;

            .circle{
                  &.one{
                        width: 40px;
                        height: 40px;
                        right: 25%;
                        top: 30px;
                  }
                  &.two{
                        width: 100px;
                        height: 100px;
                        top: -35px;
                        right: 10%;
                  }
            }
      }
      .ant-tabs{
            .ant-tabs-nav-wrap{
                  .ant-tabs-nav-list{
                        width: 50%;
                        margin: 0 auto;
                        .ant-tabs-tab{
                              div{
                                    width: 100%;
                                    text-transform: none;
                                    font-weight: 600;
                                    color: #000;
                                    opacity: 0.4;
                                    transition: opacity 0.5s;
                              }

                              &.ant-tabs-tab-active{
                                    div{
                                          opacity: 1;
                                    }
                              }

                        }
                        .ant-tabs-ink-bar{
                              background: var(--main-color) !important;
                              height: 3px;
                        }
                  }

            }

            .ant-tabs-content{
                  margin-top: 1rem;
                  font-family: var(--main-font-family);
                  ul{
                        li{
                              padding: 0.5rem 0;
                              font-weight: 500;
                              font-size: 13px;
                              color: #000;
                              span{
                                    margin-right: 1rem;
                                    background: var(--main-background-color);
                                    color: #fff;
                                    border-radius: 100%;
                                    padding: 3px 6px;
                                    font-size: 9px;
                              }

                              img{
                                    max-height: 500px;
                              }
                        }
                  }
            }
      }
}

@media screen and (max-width: 479px){
      .gallery-section{
            .wp-inner{
                  .circle{
                        &.one{
                              width: 30px;
                              height: 30px;
                              right: 35%;
                              top: 30px;
                        }
                        &.two{
                              width: 80px;
                              height: 80px;
                              top: -35px;
                              right: 10%;
                        }
                  }
            }
            .ant-tabs{
                  .ant-tabs-nav-wrap{
                        .ant-tabs-nav-list{
                              width: 100%;
                        }

                  }
            }
      }
}