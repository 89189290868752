.question-section{
      background: #f7f7f7;
      font-family: var(--main-font-family);

      .list-questions{

            font-family: var(--main-font-family);

            .ant-collapse-item{
                  margin-bottom: 2rem;
                  background: #fff;
                  border: none;
            }
            .ant-collapse-header{
                  border-bottom: 1px solid #f7f7f7;
            }
            .ant-collapse-header-text{
                  text-transform: uppercase;
                  font-size: 14px;
                  font-weight: 600;
                  color: #000;

            }

            .ant-collapse-content-box{
                 p{
                       font-size: 14px;
                       color: #000;
                       line-height: 23px;
                       font-weight: 500;
                       padding-left: 2rem;
                       position: relative;
                       margin-bottom: 1rem;

                       &::before{
                             content: '\f068';
                             font-family: 'FontAwesome';
                             font-size: 7px;
                             position: absolute;
                             left: 0;
                             top: 12px;
                             transform: translateY(-50%);
                             color: #000;
                       }

                 }
            }
      }
}