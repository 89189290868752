.step-section{
      position: relative;

      .wp-inner{
            background: var(--main-background-color);
      }
      svg{
            display: block;
      }
      .circle{
            width: 50px;
            height: 50px;
            position: absolute;
            top: 15%;
            left: 100px;
      }
      .ant-tabs{
            padding: 0 3rem;
            .ant-tabs-nav-wrap{
                  .ant-tabs-nav-list{
                        width: 100%;
                        .ant-tabs-tab{
                              width: calc(100% / 2);
                              margin: 0;

                              div{
                                    opacity: 1;
                              }

                              .ant-tabs-tab-btn{
                                    background: #a528aa;
                                    border-radius: 30px;
                                    padding: 1.5rem 0;
                                    transition: background-color 0.5s;
                                    color: #c2c2c2;
                                    font-weight: 500;
                              }

                              &.ant-tabs-tab-active{
                                    .ant-tabs-tab-btn{
                                          background: #fff;
                                          color: var(--main-color);
                                    }
                              }

                              &:nth-child(2){
                                    margin-left: -5rem !important;

                              }
                        }
                        .ant-tabs-ink-bar{
                              display: none;
                        }
                  }

            }
            .ant-tabs-content{
                  ul{
                        li{
                              color: #fff;
                              margin-bottom: 1rem;
                              h5{
                                    text-transform: uppercase;
                                    margin-bottom: 2rem;
                                    font-weight: 600;
                              }
                              span{
                                    background: #ffe200;
                                    padding: 5px 10px;
                                    border-radius: 5px;
                                    color: #000;
                                    font-weight: 500;
                              }
                              .description{
                                    font-weight: 400;
                                    p{
                                          line-height: 23px;
                                          margin-bottom: 1rem;
                                          position: relative;
                                          padding-left: 2rem;

                                          &::before{
                                                content: '\f068';
                                                font-family: 'FontAwesome';
                                                font-size: 7px;
                                                position: absolute;
                                                left: 0;
                                                top: 12px;
                                                transform: translateY(-50%);
                                                color: #fff;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (max-width: 479px){
      .step-section{
            .circle{
                  width: 30px;
                  height: 30px;
                  top: 35px;
                  left: 50px;
            }

            .ant-tabs{
                  .ant-tabs-nav-wrap{
                        .ant-tabs-nav-list{
                              display: block;
                              .ant-tabs-tab{
                                    width: 100%;

                                    .ant-tabs-tab-btn{
                                          padding: 1.5rem 10px;
                                    }

                                    &:nth-child(2){
                                          margin-left: 0 !important;
                                    }
                              }
                        }

                  }
            }
      }
}