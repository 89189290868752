.banner-top-section{
      .slick-slider{
            background: #f7f7f7;
      }
      .slider-item{
            font-family: var(--main-font-family);
            background: #f7f7f7;
            padding: 3rem 0;
            min-height: 470px;
            .d-flex{
                  padding: 0 3rem;
                  display: flex;
                  align-items: center;

                  > div{
                        width: 50%;
                        padding: 0 2rem ;
                  }

                  .thumb{
                        position: relative;
                        width: 70%;
                        img{
                              max-width: 100%;
                        }
                        .circle{
                              position: absolute;
                              border-radius: 100%;
                              z-index: 1000;
                        }
                        .one{
                              width: 18px;
                              height: 18px;
                              top: 10%;
                              right: 38%;
                        }
                        .two{
                              width: 36px;
                              height: 36px;
                              top: 9%;
                              right: 45%;
                        }

                        .three{
                               width: 64px;
                               height: 64px;
                               bottom: 0;
                              left: 35%;
                         }

                        .four{
                              width: 24px;
                              height: 24px;
                              bottom: 5%;
                              left: 27%;
                        }
                  }
            }

            h3{
                  font-family: var(--three-font-family);
                  text-transform: uppercase;
                  color: var(--main-color);
                  font-weight: 400;
                  font-size: 30px;
                  margin-bottom: 3rem;
            }
            ul{
                  margin-bottom: 2rem;

                  li{
                        //text-transform: uppercase;
                        font-size: 14px;
                        color: #000;
                        font-weight: 500;
                        padding: .4rem 0 .4rem 20px;
                        position: relative;

                        &::before{
                              content: '\f058';
                              font-family: 'FontAwesome';
                              font-size: 9px;
                              position: absolute;
                              left: 0;
                              top: 50%;
                              transform: translateY(-50%);
                              color: var(--main-color);
                        }
                  }
            }

            .description{
                  margin-bottom: 2rem;

                  p{
                        font-size: 13px;
                        color: #808080;
                        line-height: 23px;
                  }
            }

            .wp-btn{
                  button{
                        font-family: var(--main-font-family);
                        background: var(--main-background-button);
                        color: #000;
                        font-weight: 400;
                        border-radius: 25px;
                        height: 50px;
                        //text-transform: uppercase;
                        font-size: 16px;
                        padding: 0 20px;
                        border: none;
                  }
            }
      }
}


@media screen and (max-width: 479px){
      .banner-top-section{
            .slider-item{
                  .d-flex{
                        > div{
                              width: 100%;
                        }

                        .thumb{
                              display: none;
                        }
                  }
            }
      }
}