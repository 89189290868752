.wp-wrapper{
      position: relative;

      .btn-fixed{
            position: fixed;
            bottom: 30px;
            z-index: 1000;
            right: 20px;
            button{
                  height: 48px;
                  background: #e67e22;
                  color: #fff !important;
                  font-family: var(--main-font-family);
                  font-weight: 500;
                  border: none;
            }
      }
}

