section{
      .wp-inner{
            padding: 4rem 3rem;
      }

      .title-section{
            text-align: center;
            font-family: var(--three-font-family);
            text-transform: uppercase;
            color: #000;
            font-weight: 500;
            font-size: 30px;
            width: 50%;
            margin: 0  auto 5rem auto;
            line-height: 40px;

            &.white{
                  color: #fff;
            }
      }
}


.ant-tabs{
      .ant-tabs-nav-wrap{
            .ant-tabs-nav-list{
                  width: 100%;
                  .ant-tabs-tab{
                        width: calc(100% / 3);
                        text-align: center;

                        .title-tab{
                              white-space: normal;
                        }

                        > div{
                              margin-bottom: 1rem;
                        }
                        .ant-avatar{
                              margin-bottom: 1rem;
                        }
                        div{
                              width: 100%;
                              text-transform: uppercase;
                              font-weight: 400;
                              color: var(--main-color);
                              opacity: 0.6;
                              transition: opacity 0.5s;
                        }

                        &.ant-tabs-tab-active{
                              div{
                                    opacity: 1;
                              }
                        }

                  }
                  .ant-tabs-ink-bar{
                        background: var(--main-color) !important;
                        height: 3px;
                  }
            }

      }

      .ant-tabs-content{
            margin-top: 1rem;
            font-family: var(--main-font-family);
            ul{
                  li{
                        padding: 0.5rem 0;
                        font-weight: 500;
                        font-size: 13px;
                        color: #000;
                        span{
                              margin-right: 1rem;
                              background: var(--main-background-color);
                              color: #fff;
                              border-radius: 100%;
                              padding: 3px 6px;
                              font-size: 9px;
                        }
                  }
            }
      }
}

.circle{
      position: absolute;
      border-radius: 100%;
      z-index: 1000;

      &.second{
            background: #ffe200;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to bottom, #ffe200, #ffb200);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to bottom,  #ffe200, #ffb200); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
      &.main{
            background: #dd90e8;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to bottom, #dd90e8, #a73fac);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to bottom,  #dd90e8, #a73fac); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
}


@media screen and (max-width: 479px){
      section{
            .title-section{
                  width: 100%;
            }
      }
}