footer{
      font-family: var(--main-font-family);
      position: relative;

      .circle{
            width: 100px;
            height: 100px;
            top: 40px;
            left: 200px;
      }
      svg{
            display: block;
      }
      .wp-inner{
            padding: 1rem 3rem;
            background: var(--main-background-color);

            .top{
                  display: flex;
                  border-bottom: 1px solid #cdcdcd;
                  padding: 2rem 0;

                  > div{
                        width: calc(100% / 4);
                        padding: 0 15px;

                        h4{
                              color: #fff;
                              text-transform: uppercase;
                              font-weight: 600;
                              font-size: 14px;
                              margin-bottom: 1rem;
                        }

                        ul{
                              li{
                                    color: #fff;
                                    padding: .5rem 0;
                                    font-size: 13px;
                                    word-break: break-all;
                                    a{
                                          display: block;
                                          color: #fff;
                                          word-break: break-all;
                                    }
                              }
                        }
                  }

                  .logo{
                        .thumb{
                              width: 150px;
                              display: block;
                              img{
                                    max-width: 100%;
                              }
                        }
                  }
            }

            .bottom{
                  padding: 2rem 0;
                  p{
                        color: #fff;
                        font-size: 13px;
                        line-height: 23px;
                  }
            }
      }
}

.copyright{
      background: var(--second-background-color);
      font-family: var(--main-font-family);
      text-align: center;

      p{
            color: #fff;
            padding: 1.5rem 0;
            margin: 0;
            font-weight: 400;
            font-size: 13px;
      }
}


@media screen and (max-width: 479px){
      footer{
            .circle{
                  width: 80px;
                  height: 80px;
                  top: 0;
                  left: 50px;
            }
            .wp-inner{
                  .top{
                        flex-wrap: wrap;
                        > div{
                              width: 100%;
                              margin-bottom: 2rem;
                        }
                  }
            }
      }

      .copyright{
            p{
                  span{
                        padding: 1rem 0;
                        display: inline-block;
                  }
            }
      }

}