.feature-top-section{

      font-family: var(--main-font-family);
      position: relative;
      .circle{
            &.one{
                  width: 50px;
                  height: 50px;
                  right: 145px;
                  top: 70px;
            }
            &.two{
                  width: 90px;
                  height: 90px;
                  left: 145px;
                  top: 40px;
            }
      }
      .wp-inner{
            background: var(--main-background-color);
      }
      svg{
            display: block;
      }
      .ant-tabs{
            .ant-tabs-nav-wrap{
                  .ant-tabs-nav-list{
                        width: 100%;
                        .ant-tabs-tab{
                              width: calc(100% / 4);

                              div{
                                    text-transform: none;
                                    color: #fff;
                                    font-weight: 500;
                                    font-size: 16px;
                              }

                              .tab-icon{
                                    background: #fff;
                                    padding: 24px;
                                    margin-bottom: 2rem;
                                    display: inline-block;
                                    border-radius: 50%;
                                    i{
                                          color: var(--main-color);
                                          font-size: 26px;

                                    }
                              }

                        }
                        .ant-tabs-ink-bar{
                              background: #fff !important;
                        }
                  }

            }
            .ant-tabs-content{
                  ul{
                        li{
                              color: #fff;
                              span{
                                    background: #fff;
                                    color: var(--main-color);
                              }
                        }
                  }
            }
      }
}

.feature-bottom-section{
      //font-family: var(--main-font-family);
      .app{
            display: flex;
            align-items: center;
            padding: 4rem 3rem;

            > div{
                  width: 50%;
                  text-align: center;
                  position: relative;

                  img{
                        max-width: 100%;
                        width: 60%;
                        margin: 0 auto;
                  }
                  .circle{
                        width: 80px;
                        height: 80px;
                        bottom: 0;
                        left: 10%;
                  }
            }

            .left{
                  .head{
                        margin-bottom: 3rem;
                        font-family: var(--three-font-family);
                        p{
                              color: #000;
                              font-weight: 600;
                              font-size: 26px;
                              margin-bottom: 1.5rem;

                              &:nth-child(2){
                                    color: var(--main-color);
                                    font-size: 30px;
                                    text-transform: uppercase;
                                    font-weight: 500;
                              }
                        }


                  }
                  .mid{
                        font-family: var(--main-font-family);
                        width: 50%;
                        margin: 0 auto 5rem auto;
                        p{
                              font-size: 14px;
                              line-height: 23px;
                              font-weight: 500;
                              color: #000;
                        }
                  }

                  .footer{
                        font-family: var(--main-font-family);
                        button{
                              height: 50px;
                              padding: 0 3rem;
                              color: #fff;
                              border: none;
                              border-radius: 25px;
                              background: var(--main-background-color);
                              font-size: 18px;
                              font-weight: 400;
                              i{
                                    margin-right: 1rem;
                              }

                              &:first-child{
                                    margin-right: 1rem;
                              }
                              &:last-child{
                                    background: #ffc900;
                              }

                        }
                  }
            }
      }
}

@media screen and (max-width: 479px) {
      .feature-top-section{
            .circle{
                  &.one{
                        width: 30px;
                        height: 30px;
                        right: 10%;
                        top: 15px;
                  }
                  &.two{
                        width: 70px;
                        height: 70px;
                        left: 10%;
                        top: 0;
                  }
            }

            .ant-tabs{
                  .ant-tabs-nav-wrap{
                        .ant-tabs-nav-list{
                              width: 100%;
                              .ant-tabs-tab{
                                    width: calc(100% / 4);
                                    display: flex;
                                    align-items: flex-start;

                                    .tab-icon{
                                          padding: 16px 18px;
                                          i{
                                                font-size: 18px;
                                          }
                                    }

                              }
                        }

                  }
            }
      }


      .feature-bottom-section{
            .app{
                  > div{
                        .circle{
                              width: 60px;
                              height: 60px;
                              bottom: -60px;
                        }
                  }

                  .left{
                        width: 65%;
                        .mid{
                              width: 100%;
                        }

                        .footer{
                              button{
                                    width: 100%;

                                    &:first-child{
                                          margin-bottom: 1rem;
                                    }
                              }
                        }
                  }
                  .right{
                        width: 35%;
                  }
            }
      }
}