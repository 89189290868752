.customer-section{
      font-family: var(--main-font-family);
      ul{
            display: flex;
            flex-wrap: wrap;

            li{
                  width: calc(100% / 3 - 2rem);
                  text-align: center;
                  padding: 0 1rem;

                  .thumb{
                        width: 70px;
                        margin: 0 auto;
                        min-height: 90px;

                        img{
                              max-width: 100%;
                        }
                  }

                  .ant-avatar{
                        margin-bottom: 2rem;

                  }

                  h5{
                        margin-bottom: 2rem;
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 600;
                        color: var(--main-color);
                  }

                  .content{
                        p{
                              line-height: 20px;
                              font-size: 13px;
                              color: #000;
                              font-weight: 500;

                        }
                  }
            }
      }
}

@media screen and (max-width: 479px){
      .customer-section{
            font-family: var(--main-font-family);
            ul{
                  li{
                        width: 100%;
                        margin-bottom: 3rem;
                  }
            }
      }
}