.utility-section {
      font-family: var(--main-font-family);

      .ant-tabs-tab {
            .thumb{
                  min-height: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 2rem;
                  img {
                        width: 60px;
                  }
            }
            p.title-tab{
                  white-space: normal;
            }
      }
}

@media screen and (max-width: 479px) {
      .ant-tabs{
            .ant-tabs-nav-wrap{
                  .ant-tabs-nav-list{
                        flex-wrap: wrap;
                        .ant-tabs-tab{
                              width: calc(100% / 3);
                              margin: 0;
                              display: flex;
                              align-items: flex-start;

                              .thumb{
                                    margin-bottom: 0;
                                    img {
                                          width: 30px;
                                    }
                              }
                        }
                  }
            }
      }
}