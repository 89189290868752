.review-section{
      background-color: #f7f7f7;
      padding-bottom: 5rem;

      .wp-inner{
            position: relative;

            .circle{
                  width: 70px;
                  height: 70px;
                  top: -30px;
                  left: 10%;
            }
      }

      .review-item{
            font-family: var(--main-font-family);
            .inner{
                  background-color: #fff;
                  padding: 3rem 1rem;
                  margin-right: 1rem;
            }
            .head{
                  display: flex;
                  align-items: center;
                  margin-bottom: 2rem;

                  .ant-avatar{
                        margin-right: 3rem;
                  }

                  .right{
                        p{
                              color: #000;
                              font-weight: 500;
                              font-size: 13px;

                              &:first-child{
                                    font-weight: 600;
                                    font-size: 16px;
                                    margin-bottom: 0.5rem;
                              }
                        }
                  }
            }

            .content{
                  font-weight: 400;
                  padding-right: 2rem;
                  font-size: 14px;
                  line-height: 24px;

            }
      }
}