header{
      font-family: var(--main-font-family);

      .wp-inner{
            padding: 1rem 3rem;
            display: flex;
            align-items: center;

            .thumb{
                  width: 150px;
                  img{
                        max-width: 100%;
                  }
            }

            p.greeting{
                  margin-left: 3rem;
                  font-size: 18px;
                  text-transform: uppercase;
                  font-weight: 600;
                  color: #000;

                  span{
                        color: var(--main-color);
                  }
            }
      }
}

@media screen and (max-width: 479px){
      header{
            .wp-inner{
                  .thumb{
                        width: 250px;
                  }
            }
      }
}