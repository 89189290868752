.service-section{
      font-family: var(--main-font-family);
      position: relative;

      ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                  width: 45%;
                  margin-bottom: 3rem;
                  h5{
                        color: #fff;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 2rem;
                  }
                  p{
                        color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                  }
            }
      }

      .wp-inner{
            position: relative;
            background: var(--main-background-color);
      }

      .circle{
            &.one{
                  width: 50px;
                  height: 50px;
                  right: 25%;
                  bottom: 20px;
            }
            &.two{
                  width: 100px;
                  height: 100px;
                  bottom: -50px;
                  right: 10%;
            }
      }

      svg{
            display: block;
            background: #f7f7f7;
      }
}

@media screen and (max-width: 479px){
      .service-section{
            ul{
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  li{
                        width: 100%;
                  }
            }

            .circle{
                  &.one{
                        width: 30px;
                        height: 30px;
                        right: 40%;
                        bottom: 20px;
                  }
                  &.two{
                        width: 70px;
                        height: 70px;
                        bottom: -35px;
                        right: 10%;
                  }
            }


            svg{
                  display: block;
                  background: #f7f7f7;
            }
      }
}