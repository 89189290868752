body {
      --main-font-family: 'Montserrat';
      --second-font-family: 'Baloo2';
      --three-font-family: 'PlayFairDisplay';
      --main-background-color: #be43c3;
      --second-background-color: #a72aac;
      --three-background-color: #cc4cd1;
      --main-color: #be43c3;
      --main-background-button: #ffc100;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #000 !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

/* width */
::-webkit-scrollbar {
      width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
      background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
      background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      background: #555;
}

@mixin includeWidthHeight($x, $y : $x) {
      width: $x;
      height: $y;
}