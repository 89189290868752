.wp-help-page{
      font-family: var(--main-font-family) !important;

      input{
            font-family: var(--main-font-family);
      }
      .head-page{
            background: #DE85E2;
            padding: 30px 0;
            text-align: center;
            .title{
                  margin-bottom: 20px;
                  color: #fff;
                  font-size: 16px;
                  font-weight: 500;
            }

            .search-box{
                  width: 50%;
                  margin: 0 auto;
                 input{
                       height: 40px;
                       box-shadow: none !important;
                       outline: none !important;
                       border: none;
                       color: #DE85E2;
                       &::placeholder{
                             color: #DE85E2;
                       }
                 }
            }

            .wp-inner{
                  padding: 10px;
            }
      }

      .wp-content-page{
            .wp-inner{
                  padding: 30px;
                  .wp-section-question{
                        &.desktop{
                              display: block;
                        }
                        &.mobile{
                              display: none;
                        }

                        .question-box-head{
                              margin-right: 40px;
                              width: 400px;
                              background: #D9D9D966;

                              font-size: 14px;
                              margin-bottom: 20px;

                              p{
                                    display: flex;
                                    justify-content: space-between;
                                    color: #CC62D0;
                                    font-weight: 500;
                                    padding: 20px 10px;
                              }
                        }
                        .question-box-content{
                              display: flex;
                              //flex-wrap: wrap;

                              > div{
                                    &:first-child{
                                          width: 400px;
                                          text-align: left;
                                          //padding-right: 40px;

                                          p{
                                                width: 70%;
                                                padding-left: 10px;
                                          }
                                    }
                                    &:last-child{
                                          width: calc(100% - 400px);
                                          padding: 0 50px;
                                          p{
                                                margin-bottom: 20px;
                                          }
                                    }

                                    .item-question{
                                          margin-bottom: 15px;
                                          cursor: pointer;
                                          > p{
                                                font-size: 13px;
                                                line-height: 26px;
                                                color: #000;
                                                &.active{
                                                      color: #C24FC7;
                                                      font-weight: 600;
                                                }

                                                &:hover{
                                                      text-decoration: underline;
                                                      color: #C24FC7;
                                                      transition: color 0.2s;
                                                }
                                          }

                                    }
                              }


                              .answer-item{
                                    p{
                                          font-size: 14px;
                                          line-height: 26px;
                                          color: #000;
                                    }
                                    .title{
                                          color: #C24FC7;
                                          font-weight: 600;
                                          margin-bottom: 20px;
                                    }
                                    .image{
                                          display: flex;
                                          justify-content: space-between;
                                          align-items: center;

                                          > div{
                                                width: 100%;
                                                margin: 0 auto;
                                                //&:first-child{
                                                //      width: 90%;
                                                //}
                                                &:last-child{
                                                      width: 75%;
                                                }

                                                text-align: center;

                                                img{
                                                      max-width: 100%;
                                                }


                                          }
                                          &.one{
                                                > div{
                                                      width: 100%;
                                                }

                                          }
                                    }
                                    p.desc{
                                          span{
                                                font-weight: 600;
                                          }
                                    }

                                    .step{
                                          .title-step{
                                                margin-bottom: 20px;
                                                font-weight: 600;
                                          }
                                          span.step{
                                                margin-right: 10px;
                                                font-weight: 600;
                                          }
                                    }
                                    .note{
                                          p{
                                                color: #C24FC7;
                                          }
                                    }
                              }


                        }
                  }

                  .contact-block{
                        p{
                              color: #C24FC7 !important;
                              margin-bottom: 10px !important;
                        }
                        .title-contact{
                              font-size: 14px;
                              color: #C24FC7 !important;
                              font-weight: 600;
                              margin-bottom: 20px;
                        }
                  }
            }

      }

      .wp-footer-page{
            background: #DE85E2;
            padding: 30px 0;
            text-align: center;


            .wp-inner{
                  padding: 10px;
            }

            .title{
                  margin-bottom: 20px;
                  color: #fff;
                  font-size: 16px;
                  font-weight: 500;
                  font-family: var(--main-font-family);
            }
            .search-box{
                  width: 70%;
                  margin: 0 auto;
                  display: flex;
                  align-items: center;

                  .ant-input-affix-wrapper{
                        margin: 0 10px;
                  }

                  .ant-input-prefix{
                        i{
                              color: #DE85E2;
                        }
                  }
                  input{
                        height: 40px;
                        box-shadow: none !important;
                        outline: none !important;
                        border: none !important;
                        color: #DE85E2;
                        font-size: 14px;
                        padding-left: 10px;
                        &::placeholder{
                              color: #DE85E2;
                        }



                  }
            }
      }

      .wp-copy-right{
            //background: #DE85E2;

            text-align: center;
            i{
                  color: #DE85E2;
                  font-size: 18px;
                  padding: 20px 0;
                  margin-right: 20px;
            }
      }
}

.txt-active{
      color: #f1c40f;
}

@media screen and (max-width: 700px){
      .wp-help-page{
            .head-page{
                  .search-box{
                        width: 100%;
                  }
            }

            .wp-content-page {
                  .wp-inner {
                        padding: 30px;

                        .wp-section-question {
                              img{
                                    max-width: 100%;
                              }
                              &.desktop{
                                    display: none;
                              }
                              &.mobile{
                                    display: block;

                                    .question-box-head{
                                          width: 100%;
                                          margin-right: 0;
                                    }

                                    .ant-collapse{
                                          font-family: var(--main-font-family);
                                          border: none;

                                          .ant-collapse-expand-icon{
                                                position: absolute;
                                                right: 0;
                                          }
                                          .ant-collapse-header-text{
                                                padding-right: 20px;
                                                font-weight: 500;
                                          }

                                          .ant-collapse-item-active{
                                                .ant-collapse-header{
                                                      background: #C24FC7;
                                                      border-top-right-radius: 5px;
                                                      border-top-left-radius: 5px;
                                                }
                                                .ant-collapse-header-text{

                                                      color: #fff;
                                                      font-weight: 500;
                                                }
                                                .ant-collapse-expand-icon{
                                                      color: #fff;
                                                      font-weight: 600;
                                                }
                                          }

                                          .answer-item{
                                                p{
                                                      font-size: 14px;
                                                      line-height: 26px;
                                                      color: #000;
                                                }
                                                .title{
                                                      color: #C24FC7;
                                                      font-weight: 600;
                                                      margin-bottom: 20px;
                                                }
                                                .image{
                                                      display: flex;
                                                      justify-content: space-between;
                                                      align-items: center;

                                                      > div{
                                                            width: 100%;
                                                            margin: 0 auto;
                                                            //&:first-child{
                                                            //      width: 90%;
                                                            //}
                                                            &:last-child{
                                                                  width: 75%;
                                                            }

                                                            text-align: center;

                                                            img{
                                                                  max-width: 100%;
                                                            }


                                                      }
                                                      &.one{
                                                            > div{
                                                                  width: 100%;
                                                            }

                                                      }
                                                }
                                                p.desc{
                                                      span{
                                                            font-weight: 600;
                                                      }
                                                }

                                                .step{
                                                      .title-step{
                                                            margin-bottom: 20px;
                                                            font-weight: 600;
                                                      }
                                                      span.step{
                                                            margin-right: 10px;
                                                            font-weight: 600;
                                                      }
                                                }
                                                .note{
                                                      p{
                                                            color: #C24FC7;
                                                      }
                                                }
                                          }
                                    }

                                    .question-box-content{
                                          .left{
                                                width: 100%;
                                                font-family: var(--main-font-family);
                                          }
                                    }
                              }
                        }
                        .content-question.mobile.question-box-content{
                              display: block;
                              margin-top: 30px;
                              font-size: var(--main-font-family);

                              > div{
                                    width: 100%;
                                    padding: 0 !important;
                              }
                              p{
                                    font-size: 14px;
                                    line-height: 26px;
                                    width: 100% !important;
                              }

                        }
                  }
            }
      }
}
